.footer {
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
.footerIcon {
  font-size: 1.75rem;
  color: #00223e;
  margin: 0 1.5rem 1rem 1.5rem;
}
.footerIcon:hover {
  color: white;
}
.footerIcon:link {
  text-decoration: none;
}
.footerIcon:visited {
  text-decoration: none;
}
@media only screen and (max-width: 680px) {
  .footer {
    margin-top: 2rem;
  }
}
