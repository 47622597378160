@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.projects {
  background: #4b79a1; 
  background: -webkit-linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  width: 100%;
  height: 80vh;
  border-radius: 5px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.projects::-webkit-scrollbar {
  width: 8px;
}
.projects::-webkit-scrollbar-track {
  background: none;
}
.projects::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.flexDesign {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.circle {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.title {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.flexContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}
.projectItem {
  width: 45%;
  margin-bottom: 2%;
  text-align: center;
  
}
.projectTitle {
  font-size: 1.75rem;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: center;
  margin: 3% 0 2% 0;
}
.projectImage {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.projectDetails {
  width: 100%;
}
.text {
  color: #f5f5f5;
  font-size: 1rem;
  text-align: center;
  font-family: "Lato";
  margin-bottom: 2%;
}
.githubLink {
  color: #039b9b;
  text-align: center;
}
.hr {
  width: 50%;
  margin: auto;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  position: relative;
  bottom: -5%;
}
@media only screen and (max-width: 760px) {
  .flexContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .projectItem {
    width: 100%;
    margin-bottom: 2%;
    text-align: center;
  }
}
@media only screen and (max-width: 680px) {
  .flexDesign {
    display: none;
  }
}