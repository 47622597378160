@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.about {
  background: #4b79a1; 
  background: -webkit-linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  height: 80vh;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.flexDesign {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.circle {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.flexAlign {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}
.title {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.subTitle {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 2rem;
  margin-bottom: 3%;
}
.aboutContent {
  color: #f5f5f5;
  font-size: 1.4rem;
  font-family: "Lato";
  padding-bottom: 0.5rem;
}
.hr {
  width: 50%;
  margin: auto;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  position: relative;
  bottom: -20%;
}
@media only screen and (max-height: 640px) {
  .about {
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 800px) {
  .flexAlign {
    width: 80%;
  }
}
@media only screen and (max-width: 680px) {
  .flexDesign {
    display: none;
  }
  .about {
    overflow-y: scroll;
  }
}