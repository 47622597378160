.chevronRight {
    position: absolute;
    top: 40%;
    right: 0;
    font-size: 7rem;
    color:#00223e;
  }
  @media only screen and (max-width: 1120px) {
    .chevronRight {
      font-size: 5rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .chevronRight {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 680px) {
    .chevronRight {
      display: none;
    }
  }