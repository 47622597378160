@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Share Tech Mono", sans-serif;
}
.root {
  height: 100%;
  scrollbar-width: none;
  overflow: hidden;
}
.container-fluid {
  width: 85%;
  height: 80%;
  margin: 0 auto 0 auto;
  border-radius: 5px;;
  display: flex
}
.App {
  background: #4b79a1; 
  background: linear-gradient(
    to right bottom,
    #283e51,
    #4b79a1
  ); 
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  scrollbar-width: none;
}
@media only screen and (max-width: 680px) {
  .container-fluid {
    width: 100vw;
    border-radius: 0;
  }
  .app {
    display: block;
  }
}

.About_about__2P5xE {
  background: #4b79a1; 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  height: 80vh;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.About_flexDesign__UPpry {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.About_circle__3Otix {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.About_flexAlign__25q-U {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}
.About_title__2uHJk {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.About_subTitle__HhZNa {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 2rem;
  margin-bottom: 3%;
}
.About_aboutContent__18yJ0 {
  color: #f5f5f5;
  font-size: 1.4rem;
  font-family: "Lato";
  padding-bottom: 0.5rem;
}
.About_hr__2ckZr {
  width: 50%;
  margin: auto;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  position: relative;
  bottom: -20%;
}
@media only screen and (max-height: 640px) {
  .About_about__2P5xE {
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 800px) {
  .About_flexAlign__25q-U {
    width: 80%;
  }
}
@media only screen and (max-width: 680px) {
  .About_flexDesign__UPpry {
    display: none;
  }
  .About_about__2P5xE {
    overflow-y: scroll;
  }
}
.Contact_contact__gCJlu {
  background: #4b79a1;
  background: linear-gradient(to left top, #283e51, #4b79a1);
  height: 80vh;
  width: 85vw;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: scroll;
}
.Contact_contact__gCJlu::-webkit-scrollbar {
  width: 8px;
}
.Contact_contact__gCJlu::-webkit-scrollbar-track {
  background: none;
}
.Contact_contact__gCJlu::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.Contact_flexDesign__2WQlp {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.Contact_circle__xMIFX {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.Contact_flexContainer__KWrkL {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Contact_title__17fg9 {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.Contact_contactLink__1bhLe {
  color: #f5f5f5;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1%;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.Contact_link__zCEqO {
  font-size: 2rem;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
@media only screen and (max-width: 1000px) {
  .Contact_flexContainer__KWrkL {
    width: 90%;
  }
}
@media only screen and (max-width: 775px) {
  .Contact_contactLink__1bhLe {
    font-size: 1.25rem;
  }
  .Contact_span__10jmu {
    font-size: 1.75rem;
  }
}
@media only screen and (max-width: 680px) {
  .Contact_contact__gCJlu {
    width: 100vw;
  }
  .Contact_flexDesign__2WQlp {
    display: none;
  }
  .Contact_contactLink__1bhLe {
    font-size: 1.1rem;
  }
  .Contact_span__10jmu {
    font-size: 1.75rem;
  }
}

.Projects_projects__3155d {
  background: #4b79a1; 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  width: 100%;
  height: 80vh;
  border-radius: 5px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Projects_projects__3155d::-webkit-scrollbar {
  width: 8px;
}
.Projects_projects__3155d::-webkit-scrollbar-track {
  background: none;
}
.Projects_projects__3155d::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.Projects_flexDesign__20WhX {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.Projects_circle__29EPP {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.Projects_title__RSjVA {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.Projects_flexContainer__Xw87s {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}
.Projects_projectItem__3HrxE {
  width: 45%;
  margin-bottom: 2%;
  text-align: center;
  
}
.Projects_projectTitle__1v31c {
  font-size: 1.75rem;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: center;
  margin: 3% 0 2% 0;
}
.Projects_projectImage__2_oar {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Projects_projectDetails__1wPRF {
  width: 100%;
}
.Projects_text__rlQMp {
  color: #f5f5f5;
  font-size: 1rem;
  text-align: center;
  font-family: "Lato";
  margin-bottom: 2%;
}
.Projects_githubLink__1Az6j {
  color: #039b9b;
  text-align: center;
}
.Projects_hr__21PN- {
  width: 50%;
  margin: auto;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  position: relative;
  bottom: -5%;
}
@media only screen and (max-width: 760px) {
  .Projects_flexContainer__Xw87s {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .Projects_projectItem__3HrxE {
    width: 100%;
    margin-bottom: 2%;
    text-align: center;
  }
}
@media only screen and (max-width: 680px) {
  .Projects_flexDesign__20WhX {
    display: none;
  }
}
.Home_home__2ifuu {
  background: #4b79a1; 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  height: 80vh;
  width: 85vw;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Home_home__2ifuu::-webkit-scrollbar {
  width: 8px;
}
.Home_home__2ifuu::-webkit-scrollbar-track {
  background: none;
}
.Home_home__2ifuu::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.Home_flexDesign__2oGam {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.Home_circle__2ev37 {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.Home_name__1_MME {
  letter-spacing: 1rem;
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  color: white;
  padding-top: 8%;
}
.Home_title__2jL4m {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: center;
  font-size: 4rem;
  padding-top: 1%;
}
.Home_languages__1ibTY {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 3%;
}
.Home_btn__1w2JD {
  font-size: 1rem;
  color: white;
  background-color: #00223e;
  border-style: none;
  border-radius: 20px;
  height: 2rem;
  width: 30%;
  display: block;
  margin: auto;
  cursor: pointer;
}
@media only screen and (max-width: 680px) {
  .Home_home__2ifuu {
    width: 100%;
  }
  .Home_flexDesign__2oGam {
    display: none;
  }
  .Home_title__2jL4m {
    font-size: 3rem;
  }
  .Home_btn__1w2JD {
    border-radius: 20px;
    height: 2.25rem;
    width: 50%;
    display: block;
    margin: auto;
    cursor: pointer;
  }
  .Home_languages__1ibTY {
    padding-top: 3%;
    padding-bottom: 10%;
  }
}
.Navbar_navbar__1HfJ- {
  width: 85%;
  margin: auto;
  /* height: 5%; */
  display: flex;
  justify-content: space-around;
  /* position: absolute;
  top: 2.5%;
  z-index: 10; */
}
.Navbar_navLinkIcon__qapW1 {
  display: none;
}
.Navbar_navLinkContainer__2kuJc {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.Navbar_navLink__23hjr {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}
.Navbar_navLink__23hjr:visited {
  text-decoration: none;
}
.Navbar_navLink__23hjr:hover {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.Navbar_navLinkActive__q55Kj {
  color: white;
  text-decoration: underline;
}
@media only screen and (max-width: 680px) {
  .Navbar_navbar__1HfJ- {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .Navbar_navLinkContainer__2kuJc {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    height: 0;
    overflow: hidden;
    background-color: black;
  }
  .Navbar_navLink__23hjr {
    text-align: center;
    background-color: black;
    border-bottom: 1px solid gray;
    font-size: 2rem;
    line-height: 3rem;
    width: 100%;
  }
  .Navbar_showNavLink__1T8__ {
    height: 13rem;
  }
  .Navbar_navLinkIcon__qapW1 {
    display: block;
    color: white;
    font-size: 3.25rem;
    margin: 0.25rem 0;
    margin-left: 5%;
    margin-top: 0.5rem;
  }
  .Navbar_navLinkActive__q55Kj {
    background-color: black;
  }
}

.LeftChevron_chevronLeft__27_P1 {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: 7rem;
  color: #4b79a1;
}
@media only screen and (max-width: 1120px) {
  .LeftChevron_chevronLeft__27_P1 {
    font-size: 5rem;
  }
}
@media only screen and (max-width: 800px) {
  .LeftChevron_chevronLeft__27_P1 {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 680px) {
  .LeftChevron_chevronLeft__27_P1 {
    display: none;
  }
}

.RightChevron_chevronRight__33S_1 {
    position: absolute;
    top: 40%;
    right: 0;
    font-size: 7rem;
    color:#00223e;
  }
  @media only screen and (max-width: 1120px) {
    .RightChevron_chevronRight__33S_1 {
      font-size: 5rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .RightChevron_chevronRight__33S_1 {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 680px) {
    .RightChevron_chevronRight__33S_1 {
      display: none;
    }
  }
.Footer_footer__2EUkO {
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
.Footer_footerIcon__3bMdW {
  font-size: 1.75rem;
  color: #00223e;
  margin: 0 1.5rem 1rem 1.5rem;
}
.Footer_footerIcon__3bMdW:hover {
  color: white;
}
.Footer_footerIcon__3bMdW:link {
  text-decoration: none;
}
.Footer_footerIcon__3bMdW:visited {
  text-decoration: none;
}
@media only screen and (max-width: 680px) {
  .Footer_footer__2EUkO {
    margin-top: 2rem;
  }
}

