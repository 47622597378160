@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Share Tech Mono", sans-serif;
}
.root {
  height: 100%;
  scrollbar-width: none;
  overflow: hidden;
}
.container-fluid {
  width: 85%;
  height: 80%;
  margin: 0 auto 0 auto;
  border-radius: 5px;;
  display: flex
}
.App {
  background: #4b79a1; 
  background: -webkit-linear-gradient(
    to right bottom,
    #283e51,
    #4b79a1
  ); 
  background: linear-gradient(
    to right bottom,
    #283e51,
    #4b79a1
  ); 
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  scrollbar-width: none;
}
@media only screen and (max-width: 680px) {
  .container-fluid {
    width: 100vw;
    border-radius: 0;
  }
  .app {
    display: block;
  }
}
