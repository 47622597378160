.navbar {
  width: 85%;
  margin: auto;
  /* height: 5%; */
  display: flex;
  justify-content: space-around;
  /* position: absolute;
  top: 2.5%;
  z-index: 10; */
}
.navLinkIcon {
  display: none;
}
.navLinkContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.navLink {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}
.navLink:visited {
  text-decoration: none;
}
.navLink:hover {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.navLinkActive {
  color: white;
  text-decoration: underline;
}
@media only screen and (max-width: 680px) {
  .navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .navLinkContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    height: 0;
    overflow: hidden;
    background-color: black;
  }
  .navLink {
    text-align: center;
    background-color: black;
    border-bottom: 1px solid gray;
    font-size: 2rem;
    line-height: 3rem;
    width: 100%;
  }
  .showNavLink {
    height: 13rem;
  }
  .navLinkIcon {
    display: block;
    color: white;
    font-size: 3.25rem;
    margin: 0.25rem 0;
    margin-left: 5%;
    margin-top: 0.5rem;
  }
  .navLinkActive {
    background-color: black;
  }
}
