.contact {
  background: #4b79a1;
  background: -webkit-linear-gradient(to left top, #283e51, #4b79a1);
  background: linear-gradient(to left top, #283e51, #4b79a1);
  height: 80vh;
  width: 85vw;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: scroll;
}
.contact::-webkit-scrollbar {
  width: 8px;
}
.contact::-webkit-scrollbar-track {
  background: none;
}
.contact::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.flexDesign {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.circle {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.flexContainer {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  color: #00223e;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}
.contactLink {
  color: #f5f5f5;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1%;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.link {
  font-size: 2rem;
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
@media only screen and (max-width: 1000px) {
  .flexContainer {
    width: 90%;
  }
}
@media only screen and (max-width: 775px) {
  .contactLink {
    font-size: 1.25rem;
  }
  .span {
    font-size: 1.75rem;
  }
}
@media only screen and (max-width: 680px) {
  .contact {
    width: 100vw;
  }
  .flexDesign {
    display: none;
  }
  .contactLink {
    font-size: 1.1rem;
  }
  .span {
    font-size: 1.75rem;
  }
}
