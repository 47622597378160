.home {
  background: #4b79a1; 
  background: -webkit-linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  background: linear-gradient(
    to left top,
    #283e51,
    #4b79a1
  ); 
  height: 80vh;
  width: 85vw;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.home::-webkit-scrollbar {
  width: 8px;
}
.home::-webkit-scrollbar-track {
  background: none;
}
.home::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}
.flexDesign {
  display: flex;
  justify-content: flex-start;
  margin-left: 2%;
}
.circle {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #283e51;
  background-color: #283e51;
  margin-top: 1%;
  margin-right: 1%;
}
.name {
  letter-spacing: 1rem;
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  color: white;
  padding-top: 8%;
}
.title {
  background-image: linear-gradient(
    90deg,
    rgba(255, 159, 96, 1) 0%,
    rgba(252, 91, 96, 1) 50%,
    rgba(152, 59, 125, 1) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: center;
  font-size: 4rem;
  padding-top: 1%;
}
.languages {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 3%;
}
.btn {
  font-size: 1rem;
  color: white;
  background-color: #00223e;
  border-style: none;
  border-radius: 20px;
  height: 2rem;
  width: 30%;
  display: block;
  margin: auto;
  cursor: pointer;
}
@media only screen and (max-width: 680px) {
  .home {
    width: 100%;
  }
  .flexDesign {
    display: none;
  }
  .title {
    font-size: 3rem;
  }
  .btn {
    border-radius: 20px;
    height: 2.25rem;
    width: 50%;
    display: block;
    margin: auto;
    cursor: pointer;
  }
  .languages {
    padding-top: 3%;
    padding-bottom: 10%;
  }
}