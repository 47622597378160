.chevronLeft {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: 7rem;
  color: #4b79a1;
}
@media only screen and (max-width: 1120px) {
  .chevronLeft {
    font-size: 5rem;
  }
}
@media only screen and (max-width: 800px) {
  .chevronLeft {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 680px) {
  .chevronLeft {
    display: none;
  }
}
